import React from 'react';
import PageTopBar from "../component/PageTopBar";
import MapView from "../component/MapView";
import {Collection, Query} from "../common/DB";
import {FireStore} from "../firebase/config";
import WebPage from "../component/WebPage";
import { mapboxgl } from '../component/MapView';
import Report from "../component/Report";

export default class MapPage extends WebPage {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            markers: {}
        };

        this.userObserver = null;
        this.accountObserver = null;
        this.markerObservers = [];

        this.mapInstance = null;

    }

    componentDidMount() {
        this.startProjectMarkerObservers();
    }

    startProjectMarkerObservers() {
        this.userObserver = Query.userData(this.context.auth.uid).onSnapshot(user => {
            if (this.accountObserver) this.accountObserver();
            this.accountObserver = Query.accountData(user.get('account')).onSnapshot(account => {
               if (this.markerObservers.length) {
                   for (const markerObserver of this.markerObservers) markerObserver();
               }
               for (const project of account.get('projects')) {
                   this.markerObservers.push(Query.projectMarkers(project)
                       .where('active', '==', true)
                       .onSnapshot(markers => {
                           this.setState({
                               markers: {
                                   ...this.state.markers,
                                   [project.id]: markers.docs
                               }
                           }, () => {
                               this.updateMarkerSource();
                           });
                       })
                   );
               }
            });
        });
    }

    componentWillUnmount() {
        if (this.userObserver) this.userObserver();
        if (this.accountObserver) this.accountObserver();
        if (this.markerObservers.length) {
            for (const markerObserver of this.markerObservers) markerObserver();
        }
    }

    updateMarkerSource() {
        console.log(this.state.markers);
        if (!this.mapInstance) return false;

        this.mapInstance.getSource('markers').setData(this.markerGeoJson());
    }


    /** Prepare datasource of all markers as GeoJSON **/
    markerGeoJson() {
        const features = [];

        console.log(this.state.markers);

        for (const [projectID, markers] of Object.entries(this.state.markers)) {
            for (const marker of markers) {
                const markerPosition = marker.get('position');
                features.push({
                    type: "Feature",
                    properties: {
                        marker: marker.id,
                        project: projectID,
                    },
                    geometry: {
                        type: "Point",
                        "coordinates": [markerPosition.longitude, markerPosition.latitude]
                    }
                });
            }
        }

        return {
            type: 'FeatureCollection',
            features: features
        };
    }

    renderMarkerPopup(marker) {
        console.log("Marker Hover: ", marker);
        return (<Report />);
    }

    render() {
        return (
            <div className="page-map">
                <div className="overview-map-container">
                    <MapView locationControl={true} onLoad={async map => {
                        this.mapInstance = map;
                        map.addSource('markers', {
                            type: 'geojson',
                            data: this.markerGeoJson()
                        });

                        map.addLayer(
                            {
                                'id': 'markers-point',
                                'type': 'circle',
                                'source': 'markers',
                                'paint': {
                                    'circle-radius': 5,
                                    'circle-color': 'white',
                                    'circle-stroke-color': 'black',
                                    'circle-stroke-width': 1,
                                }
                            },
                        );

                        const popup = new mapboxgl.Popup({
                            closeButton: false,
                            closeOnClick: false
                        });

                        map.on('mouseenter', 'markers-point', (e) => {
                            // Change the cursor style as a UI indicator.
                            map.getCanvas().style.cursor = 'pointer';

                            // Copy coordinates array.
                            const marker = e.features[0].properties.marker;
                            const coordinates = e.features[0].geometry.coordinates.slice();

                            // Ensure that if the map is zoomed out such that multiple
                            // copies of the feature are visible, the popup appears
                            // over the copy being pointed to.
                            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                            }

                            // Populate the popup and set its coordinates
                            // based on the feature found.
                            popup.setLngLat(coordinates).setHTML("<div>Hello World</div>").addTo(map);
                        });

                        map.on('mouseleave', 'markers-point', () => {
                            map.getCanvas().style.cursor = '';
                            popup.remove();
                        });
                    }}/>
                </div>
            </div>
        );
    }
}