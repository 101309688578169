import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const app = firebase.initializeApp({
    apiKey: "AIzaSyCbp5tIuXmqHvpfeMCTRpfZZP5JxfYGCRU",
    authDomain: "trapmaster-15ba9.firebaseapp.com",
    projectId: "trapmaster-15ba9",
    storageBucket: "trapmaster-15ba9.appspot.com",
    messagingSenderId: "919834322774",
    appId: "1:919834322774:web:724431f23dbe383233d8f7",
    measurementId: "G-97WKY27QCM"
});

const FireStore = app.firestore();
const FireStorage = app.storage();

export {
    app,
    FireStore,
    FireStorage,
}
