import React, {useEffect, useState} from "react";
import {useAuth} from "../component/AuthProvider";
import {useLocation, useNavigate} from "react-router-dom";


export default function LoginPage(props) {
    let auth = useAuth();
    let navigate = useNavigate();
    let location = useLocation();

    let from = location.state?.from?.pathname || "/";

    let [error, setError] = useState(null);
    let [username, setUsername] = useState("");
    let [password, setPassword] = useState("");

    if (auth.auth) {
        console.log("From: ", from);
        navigate('/map', { replace: true });
    }



    const execLogin = function() {
        console.log("Attempt Login", username, password);
        auth.signin(username, password).then(() => {
            console.log("Login Success");
        })
            .catch(err => {
                setError(err);
            });
    }

    return (
        <div className='page'>            
            <div className="loginContainer">
                <img 
                    className="login-logo"
                    src={'/assets/logo/TrapMasterLogo_words_white_512.png'}
                    style={{width: '100%'}}
                    alt="TrapMaster Online Logo"    
                />
                {error ? <p className="login-error">{error}</p> : ""}
                <div className="form-group mb-2">
                    <input
                        type="email"
                        id="login"
                        className="form-control"
                        name="login"
                        placeholder="Enter Email"
                        value={username}
                        onChange={(e) => {
                            console.log("Username updated to", e.target.value);
                            setUsername(e.target.value);
                        }}
                    />
                </div>

                <div className="form-group mb-2">
                    <input
                        type="password"
                        id="password"
                        className="form-control"
                        name="login"
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                    />
                </div>

                <div className="form-group mb-2">
                    <button type="submit" className="btn btn-primary"
                            onClick={() => {
                                execLogin();
                            }}
                    >Login</button>
                </div>

                <div className="form-group mb-2">
                    <a href="https://trapmaster.co.nz" className="btn btn-danger">Back to TrapMaster</a>
                </div>
            </div>
        </div>
    );
}
