import React from "react";
import {AuthContext} from "./AuthProvider";
import PageTopBar from "../component/PageTopBar";

export default class WebPage extends React.Component {
    render() {
        return (
            <div className='page-container'>
                <PageTopBar active={this.props.menu}/>
                {this.props.children}
            </div>
        );
    }
}

WebPage.contextType = AuthContext;