import React from "react";

export default class ReactFullscreenPortal extends React.Component {

    constructor(props){
        super(props);

        this.popStateListener = window.onpopstate;

        this.doReturn = () => {
            this.props.onReturn();
        };

        this.buttonListener = event => {
            if(event.keyCode === 27) {
                this.doReturn();
            }
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.buttonListener, false);
        window.onpopstate = () => {
            this.doReturn();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.buttonListener, false);
        window.onpopstate = this.popStateListener;
    }

    render() {
        return <>{this.props.children}</>;
    }
}