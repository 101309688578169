import React from "react";
import {Routes, Route, Navigate, BrowserRouter} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import MainPage from "./page/MainPage";
import MapPage from "./page/MapPage";

import { app } from './firebase/config';
import { Query } from "./common/DB";
import {AuthProvider, RequireAuth, WaitForAuth} from "./component/AuthProvider";
import LoginPage from "./page/LoginPage";
import ReportCreatePage from "./page/ReportCreatePage";
import ProjectsPage from "./page/ProjectsPage";
import WebPage from "./component/WebPage";
import SettingsPage from "./page/SettingsPage";
import {Page404} from "./page/Page404";

window.D = {};
window.D.firebase = app;

window.D.Query = Query;

class App extends React.Component {
    render() {
        return (
            <div className="App">
                <AuthProvider>
                    <WaitForAuth>
                        <BrowserRouter>
                            <Routes>
                                <Route exact path="/" element={<Navigate replace to="/map" />} />);

                                <Route path="/map" element={<RequireAuth><WebPage menu={["overview"]}><MapPage /></WebPage></RequireAuth>} />
                                <Route path="/report/create" element={<RequireAuth><WebPage menu={["reports", "create"]}><ReportCreatePage/></WebPage></RequireAuth>} />
                                <Route path="/projects" element={<RequireAuth><WebPage menu={["projects"]}><ProjectsPage/></WebPage></RequireAuth>} />
                                <Route path="/settings" element={<RequireAuth><WebPage menu={["settings"]}><SettingsPage/></WebPage></RequireAuth>} />

                                <Route path="/login" element={<LoginPage />}/>

                                <Route path="/404" element={<Page404/>} />
                                <Route path="*" element={<Navigate replace to="/404" />} />
                            </Routes>
                        </BrowserRouter>
                    </WaitForAuth>
                </AuthProvider>
            </div>
        );
    }

}

export default App;
