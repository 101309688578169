import React from "react";
import '../style/Loading.scss';

export default class Loading extends React.Component {
    render() {
        return (<div>
            <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <p>{this.props.message || "Loading..."}</p>
        </div>);
    }
}