import React from 'react';
import PageTopBar from "../component/PageTopBar";
import WebPage from "../component/WebPage";
import Loading from "../component/Loading";
import { FireStore } from '../firebase/config';
import {Query} from "../common/DB";
import { Util } from '../common/Util';

export default class ProjectsPage extends WebPage {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            projects: {},
        };
        this.projectsListener = null;
    }

    componentDidMount() {
        this.setState({loading: true});

        this.projectsListener = Util.listenTo(this.context.account.get('projects'), project => {
            if (project.get('active')) {
                this.state.projects[project.id] = project;
                this.setState({projects: this.state.projects});
            };
        }, () => {
            this.setState({loading: false});
        })
    }

    componentWillUnmount() {
        if (this.projectsListener) this.projectsListener();
    }

    render() {
        let content = null;
        if (this.state.loading) {
            content = <Loading message="Loading projects..."/>;
        }
        else {

            content = (<div>
                <h4>Your Projects</h4>
                {Object.values(this.state.projects).map(project => {
                    return <div key={project.id}>{project.get('name')}</div>;
                })}
            </div>);

        }

        return (
            <div className="page page-projects">
                {content}
            </div>
        );
    }

}