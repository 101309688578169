import React from "react";
import WebPage from "../component/WebPage";
import PageTopBar from "../component/PageTopBar";
import MapView from "../component/MapView";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import Report from "../component/Report";
import {Collection, prepareProjectReference, Query} from "../common/DB";
import {app, FireStore} from "../firebase/config";
import firebase from "firebase";
import {Util} from "../common/Util";
import * as d3 from "d3-geo";
import ReactFullscreenPortal from "../component/ReactFullscreenPortal";
import Loading from "../component/Loading";

export default class ReportCreatePage extends WebPage {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            fatal: false,
            error: null,
            projects: [],
            project: "",
            dateStart: "",
            dateEnd: "",
            region: null,
            regionMarkers: null,
            markers: null,
            openReport: false,
            companyName: "",
            reportComments: "",
            stage: 0
        };
    }

    componentDidMount() {
        this.prepareStage();
    }

    prepareStage() {
        switch (this.state.stage) {
            case 0:
                return this.prepare_stage00();

            case 2:
                return this.prepare_stage02();

            case 4:
                return this.prepare_stage04();

            default:
                return Promise.resolve();
        }
    }

    validateStage() {
        const emitError = (err) => {
            this.setState({error: err});
            return false;
        }
        emitError(null);

        // Validate Stage.
        switch (this.state.stage) {
            case 0:
                if (!this.state.project) return emitError("You must select a project.");
                break;

            case 1:
                if (!this.state.dateStart || !this.state.dateEnd) {
                    return emitError("You must select a start and end date.");
                }
                break;

            case 2:
                if (!this.state.region) return emitError("You must outline a block for the report.");
                if (this.state.region.features.length !== 1) {
                    return emitError("You must outline a single block");
                }
                break;

            default:
                break;
        }
        return true;
    }

    nextStage() {
        if (!this.validateStage()) return false;

        this.setState({ loading: true, stage: this.state.stage + 1 }, () => {
            this.prepareStage().then(() => {
                this.setState({ loading: false });
            })
        });
    }

    prevStage() {
        this.setState({ stage: this.state.stage - 1 });
    }


    selectProject(projectID) {
        this.setState({project: projectID});
    }

    prepare_stage00() {
        return new Promise((resolve, reject) => {
            const pWait = [];
            for (const projectRef of this.context.account.get('projects')) {
                pWait.push(projectRef.get().then(projectSnapshot => {
                    return {id: projectSnapshot.id, name: projectSnapshot.get('name')};
                }));
            }

            Promise.all(pWait).then(results => {
                this.setState({
                    projects: results
                }, () => {
                    resolve();
                });
            });
        });
    }

    render_stage00() {
        console.log("Rendering Projects Stage...", this.state);
        // Fetch the users projects.
        let projectOptions = [];
        if (!this.state.projects) return <div>Missing Projects...</div>;
        for (const project of this.state.projects) {
            projectOptions.push(<option key={project.id} value={project.id}>{project.name}</option>);
        }

        return (<div className="report-project-select">
            <div className="form-row">
                <div className="form-group mb-2">
                    <select id="input-report-project" className="form-control" value={this.state.project} onChange={(e) => {
                        this.selectProject(e.target.value);
                    }}>
                        <option value={""} disabled>Please select a project...</option>
                        {projectOptions}
                    </select>
                </div>
            </div>

            <div className="form-row">
                <div className="form-group">
                    <button className="btn btn-success" onClick={() => {
                        this.nextStage();
                    }}>Next</button>
                </div>
            </div>
        </div>);
    }

    render_stage01() {
        return (<div className="report-details-container">
            <div className="form-row">
                <div className="form-group mb-2">
                    <label htmlFor="report-date-start">Report Start Date</label>
                    <input id="report-date-start" type="date" className="form-control" value={this.state.dateStart} onChange={(e) => {
                        this.setState({dateStart: e.target.value});
                    }}/>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="report-date-end">Report Final Date</label>
                    <input id="report-date-end" type="date" className="form-control" value={this.state.dateEnd} onChange={(e) => {
                        this.setState({dateEnd: e.target.value});
                    }}/>
                </div>
            </div>

            <div className="form-row">
                <div className="form-group">
                    <button className="btn btn-danger" style={{marginRight: '10px'}} onClick={() => {
                        this.prevStage();
                    }}>Back</button>
                    <button className="btn btn-success" onClick={() => {
                        this.nextStage();
                    }}>Next</button>
                </div>
            </div>
        </div>);
    }

    prepare_stage02() {
        return new Promise((resolve, reject) => {
            const timestampStart = Util.htmlDateToTimestamp(this.state.dateStart);
            const timestampEnd = Util.htmlDateToTimestamp(this.state.dateEnd) + 86400;

            const firestoreTimeEnd = new firebase.firestore.Timestamp(timestampEnd, 0);

            // We want to show all markers that were "active" during the time window provided.
            FireStore.collection(Collection.Markers)
                .where('project', '==', prepareProjectReference(this.state.project))
                .where('timestampCreated', '<=', firestoreTimeEnd)
                .get()
                .then(markersSnapshot => {
                    console.log(markersSnapshot);
                    const markers = new Map();
                    for (const marker of markersSnapshot.docs) {
                        const markerTimeRemoved = marker.get('timestampRemoved');
                        if (markerTimeRemoved && markerTimeRemoved.seconds < timestampStart) continue;
                        markers.set(marker.id, marker);
                    }

                    this.setState({
                        markers: markers
                    }, () => {
                        resolve();
                    });
                });
        });
    }

    render_stage02() {
        if (this.state.loading) return (<></>);
        return (
            <div className="report-block-boundaries">
                <div style={{width: '100%', height: '500px'}}>
                    <MapView
                        locationControl={true}
                        extend={(map) => {

                        console.log("Executing extra map settings...");
                        const draw = new MapboxDraw({
                            displayControlsDefault: false,
                            // Select which mapbox-gl-draw control buttons to add to the map.
                            controls: {
                                polygon: true,
                                trash: true
                            },
                            // Set mapbox-gl-draw to draw by default.
                            // The user does not have to click the polygon control button first.
                            defaultMode: this.state.region ? 'simple_select' : 'draw_polygon'
                        });

                        // Load the region from state if exists.
                        map.on('load', () => {
                            if (this.state.region) draw.set(this.state.region);
                        });

                        map.addControl(draw);

                        const updateArea = (e) => {
                            console.log(e);
                            const data = draw.getAll();
                            this.setState({region: data});
                            console.log("Block Area Updated");
                        }

                        map.on('draw.create', updateArea);
                        map.on('draw.delete', updateArea);
                        map.on('draw.update', updateArea);

                        // Render Markers.
                        for (const [markerID, marker] of this.state.markers) {
                            let markerPosition = marker.get('position');

                            (new mapboxgl.Marker()).setLngLat([markerPosition.longitude, markerPosition.latitude]).addTo(map);
                        }
                    }}
                    />
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <button className="btn btn-danger" style={{'margin-right': '10px'}} onClick={() => {
                            this.prevStage();
                        }}>Back</button>
                        <button className="btn btn-success" onClick={() => {
                            this.nextStage();
                        }}>Next</button>
                    </div>
                </div>
            </div>
        );
    }

    render_stage03() {
        return (<div className="report-details-container">
            <div className="form-row">
                <div className="form-group mb-2">
                    <label htmlFor="input-report-company-name">Company Name</label>
                    <input id="input-report-company-name" type="text" placeholder="Company Name" className="form-control" value={this.state.companyName} onChange={(e) => {
                        this.setState({ companyName: e.target.value });
                    }}/>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="input-report-company-name">Report Comments</label>
                    <textarea id="input-report-comments" placeholder="Comments..." cols="50" rows="10" className="form-control" value={this.state.reportComments} onChange={(e) => {
                        this.setState({ reportComments: e.target.value });
                    }}/>
                </div>
            </div>

            <div className="form-row">
                <div className="form-group">
                    <button className="btn btn-danger" style={{'margin-right': '10px'}} onClick={() => {
                        this.prevStage();
                    }}>Back</button>
                    <button className="btn btn-success" onClick={() => {
                        this.nextStage();
                    }}>Next</button>
                </div>
            </div>
        </div>);
    }

    prepare_stage04() {
        return new Promise((resolve, reject) => {
            // Filter out markers that are not contained within the region specified.
            const regionalMarkers = new Map();
            for (const [markerID, marker] of this.state.markers) {
                const markerPosition = marker.get('position');
                const markerLngLat = [markerPosition.longitude, markerPosition.latitude];

                if (d3.geoContains(this.state.region, markerLngLat)) regionalMarkers.set(markerID, marker);
            }
            this.setState({regionMarkers: regionalMarkers});

            resolve();
        });
    }

    render_stage04() {
        return (
            <div className="report-download">
                <div className="form-row">
                    <button className="btn btn-danger" style={{'margin-right': '10px'}} onClick={() => {
                        this.prevStage();
                    }}>Back</button>
                    <div className="form-group col-md-2 offset-md-5">
                        <button className="btn btn-success" style={{width: '100%'}} onClick={() => {
                            this.setState({ openReport: true });
                        }}>Download Report</button>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <div className="report-container">
                            {this.generateReport()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    generateReport() {
        console.log(this.state);

        // Need to only get the markers that are contained within

        return <Report
            companyName={this.state.companyName}
            dateStart={this.state.dateStart}
            dateEnd={this.state.dateEnd}
            project={this.state.project}
            region={this.state.region}
            markers={this.state.regionMarkers}
            comments={this.state.reportComments}
            />;
    }

    render_stage() {
        let childPart = <div>An error has occurred</div>;
        let childTitle = "Error";
        switch (this.state.stage) {
            case 0:
                childTitle = "Please select the project to generate a report from";
                childPart = this.render_stage00();
                break;

            case 1:
                childTitle = "Please select the report start and final dates.";
                childPart = this.render_stage01();
                break;

            case 2:
                childTitle = "Please select the geographical boundaries for this report.";
                childPart = this.render_stage02();
                break;

            case 3:
                childTitle = "Report Details.";
                childPart = this.render_stage03();
                break;

            case 4:
                childTitle = "Results";
                childPart = this.render_stage04();
                break;

            default:
                break;
        }

        let errorMsg = "";
        if (this.state.error) {
            errorMsg = <p style={{color: 'red'}}>{this.state.error}</p>
        }

        return (<>
            <h3>{childTitle}</h3>
            {errorMsg}
            {childPart}
        </>);
    }

    render() {
        let content = <Loading/>;
        if (this.state.fatal) content = <div>An unresolvable error has occurred; [{this.state.fatal}]</div>;
        if (!this.state.loading) content = this.render_stage();


        if (this.state.openReport) {
            return (<ReactFullscreenPortal
                onReturn={() => {
                    this.setState({openReport: false})
                }}
            >
                <div className="page-map page-reports report-print">
                    {this.generateReport()}
                </div>
            </ReactFullscreenPortal>);
        }

        return (
            <div className="page page-map page-reports">
                {content}
            </div>
        );
    }
}