import React from 'react';
import MapView from "./MapView";
import {Collection, Query} from "../common/DB";
import * as d3 from "d3-geo";
import firebase from "firebase";
import {Util} from "../common/Util";
import Loading from "./Loading";
import {AuthContext} from "./AuthProvider";
import {FireStorage} from "../firebase/config";

const EVENT_CAUGHT = 3;
const EVENT_SPRUNG = 4;

export default class Report extends React.Component {
    constructor(props) {
        super(props);
        const report = this;
        this.state = {
            loading: true,

            caughtTotal: 0,
            sprungTotal: 0,
            activeDays: new Map(),
            markerStats: new Map(),

            companyLogoURL: null,
            companyName: "",
        };

        this.ReportPage = class ReportPage extends React.Component {
            renderHeader() {
                if (this.props.header === false) return null;

                return <div className="page-header" style={{width: '100%', height: '15mm', marginTop: '5mm'}}>
                    {report.state.companyLogoURL ?
                        <img
                            src={report.state.companyLogoURL}
                            style={{
                                height: '100%',
                                float: 'right'
                            }}
                        /> : <h3>{report.state.companyName}</h3>
                    }
                </div>
            }

            render() {
                return <div className="page-a4">
                    <div className="page-break"></div>
                    {this.renderHeader()}
                    {this.props.children}
                </div>
            }
        }
    }

    componentDidMount() {
        const depends = [
            this.loadReportDetails.bind(this),
            this.loadCompanyLogo.bind(this),
            this.loadCompanyDetails.bind(this),
        ];

        Promise.all(depends.map(loader => loader())).then(() => {
            this.setState({ loading: false });
        });
    }

    async loadReportDetails() {
        // Load Project Details.
        const projectDoc = await Query.fetchProject(this.props.project).get();
        this.setState({project: projectDoc});

        const events = await this.loadMarkerEvents();

        let caughtTotal = 0;
        let sprungTotal = 0;
        const dayStats = new Map();
        const markerStats = new Map();

        const ensureDayStats = (date) => {
            if (!dayStats.has(date)) {
                dayStats.set(date, {
                    caught: 0,
                    sprung: 0
                });
            }
        };

        const ensureMarkerStats = (marker) => {
            if (!markerStats.has(marker)) {
                markerStats.set(marker, {
                    caught: 0,
                    sprung: 0
                });
            }
        }

        for (const event of events) {
            const eventDate = new Date(event.get('timestamp').seconds * 1000).toDateString();
            const markerID = event.get('marker').id;

            switch (event.get('type')) {
                case EVENT_CAUGHT:
                    caughtTotal += 1;

                    // Add to day stats.
                    ensureDayStats(eventDate);
                    dayStats.get(eventDate).caught += 1;

                    // Add to marker stats.
                    ensureMarkerStats(markerID);
                    markerStats.get(markerID).caught += 1;
                    break;

                case EVENT_SPRUNG:
                    sprungTotal += 1;

                    // Add to day stats.
                    ensureDayStats(eventDate);
                    dayStats.get(eventDate).sprung += 1;

                    // Add to marker stats.
                    ensureMarkerStats(markerID);
                    markerStats.get(markerID).sprung += 1;
                    break;
            }
        }

        this.setState({
            activeDays: dayStats,
            caughtTotal: caughtTotal,
            sprungTotal: sprungTotal,
            markerStats: markerStats
        });
    }
    async loadCompanyLogo() {
        let logoPath = this.context.account.get('logo');
        if (logoPath) {
            console.log("Company Logo Path: ", logoPath);
            const storageRef = FireStorage.ref();
            const fileRef = storageRef.child(logoPath);
            try {
                const downloadURL = await fileRef.getDownloadURL();
                console.log("Company Logo URL: ", downloadURL);
                this.setState({ companyLogoURL: downloadURL });
            } catch (err) {
                console.log("Logo file does not exist!", logoPath);
                return false;
            }
        }
    }
    async loadCompanyDetails() {
        await this.setState({ companyName: this.context.account.get('name') });
    }

    loadMarkerEvents() {
        return new Promise((resolve, reject) => {

            const timestampStart = Util.htmlDateToTimestamp(this.props.dateStart);
            const timestampFinal = Util.htmlDateToTimestamp(this.props.dateEnd) + 86400;

            const firestoreTimeStart = new firebase.firestore.Timestamp(timestampStart, 0);
            const firestoreTimeFinal = new firebase.firestore.Timestamp(timestampFinal, 0);

            let waiting = [];
            for (const [markerID, marker] of this.props.markers) {
                waiting.push(marker.ref.collection('events')
                    .where('timestamp', '>=', firestoreTimeStart)
                    .where('timestamp', '<', firestoreTimeFinal)
                    .get()
                    .then((eventsSnapshot) => {
                        return { id: marker.id, events: eventsSnapshot.docs };
                    }));
            }

            Promise.all(waiting).then(results => {
                const eventsMarker = new Map();
                const eventsAll = [];

                for (const markerEventPayload of results) {
                    eventsMarker.set(markerEventPayload.id, markerEventPayload.events);
                    eventsAll.push(...markerEventPayload.events);
                }

                this.setState({ eventsAll: eventsAll, eventsMarker: eventsMarker });
                resolve(eventsAll);
            }, reject);
        });
    }

    renderDetails() {
        const summaryRow = (title, content) => <tr key={title}><th>{title}</th><td>{content}</td></tr>;

        return (<div className="report-page report-page-details">
            <h5>Report Summary</h5>
            <table>
                <tbody>
                    {summaryRow("Organisation", this.props.companyName)}
                    {summaryRow("Project", this.state.project.get('name'))}
                    {summaryRow("Start Date", this.props.dateStart)}
                    {summaryRow("Final Date", this.props.dateEnd)}
                    {summaryRow("Active Traps", this.props.markers.size)}
                    {summaryRow("Caught", this.state.caughtTotal)}
                    {summaryRow("Sprung", this.state.sprungTotal)}
                </tbody>
            </table>
        </div>);
    }

    renderDailyStats() {

        const dailyActivityRows = [];
        const activeDates = Array.from(this.state.activeDays.keys()).sort((a, b) => {
            let dateA = (new Date(a)).getTime();
            let dateB = (new Date(b)).getTime();
            return dateA - dateB;
        });

        for (const date of activeDates) {
            let dayStats = this.state.activeDays.get(date);
            dailyActivityRows.push(<tr>
                <td>{date}</td>
                <td>{dayStats.caught || 0}</td>
                <td>{dayStats.sprung || 0}</td>
            </tr>)
        }

        return (<div className="report-page report-page-daily-stats">
            <h5>Daily Report</h5>
            <table>
                <tbody>
                    <tr>
                        <th>Date</th>
                        <th>Caught</th>
                        <th>Sprung</th>
                    </tr>
                    {dailyActivityRows}
                </tbody>
            </table>
        </div>);
    }

    /** Generate GeoJSON from the marker data for rendering **/
    markerGeoJson() {
        const features = [];

        for (const [markerID, marker] of this.props.markers) {
            const markerStats = this.state.markerStats.get(markerID);
            const markerPosition = marker.get('position');

            features.push({
                type: "Feature",
                properties: {
                    id: markerID,
                    ...markerStats
                },
                geometry: {
                    type: "Point",
                    "coordinates": [markerPosition.longitude, markerPosition.latitude]
                }
            });
        }

        return {
            type: 'FeatureCollection',
            features: features
        };
    }

    drawMap(map) {
        return new Promise((resolve, reject) => {
            // Jump map to the boundary box required.
            const mapBounds = d3.geoBounds(this.props.region);
            map.fitBounds(mapBounds, { padding: 15, duration: 0 });

            map.on('load', () => {

                // Add Markers Source.
                map.addSource('markers', {
                    type: 'geojson',
                    data: this.markerGeoJson()
                });

                // Add Boundary Source.
                map.addSource('block_boundary', {
                    'type': 'geojson',
                    'data': this.props.region
                });
                this.drawBoundary(map);

                resolve();
            });
        });
    }

    /** Add Markers Layer **/
    drawMarkers(map) {
        map.addLayer(
            {
                'id': 'markers-point',
                'type': 'circle',
                'source': 'markers',
                'paint': {
                    'circle-radius': 5,
                    'circle-color': 'white',
                    'circle-stroke-color': 'black',
                    'circle-stroke-width': 1,
                }
            },
        );
    }

    /** Add Block Boundary Layer **/
    drawBoundary(map) {
        // Add a new layer to visualize the polygon.
        map.addLayer({
            'id': 'block_boundary',
            'type': 'fill',
            'source': 'block_boundary',
            'layout': {},
            'paint': {
                'fill-color': '#0080ff', // blue color fill
                'fill-opacity': 0.3
            },
        });

        // Add a black outline around the polygon.
        map.addLayer({
            'id': 'outline',
            'type': 'line',
            'source': 'block_boundary',
            'layout': {},
            'paint': {
                'line-color': '#000',
                'line-width': 3
            }
        });
    }

    /** Add Marker Caught Heatmap Layer **/
    drawHeatMap(map) {
        map.addLayer(
            {
                'id': 'markers-heat',
                'type': 'heatmap',
                'source': 'markers',
                'paint': {
                    'heatmap-weight': ['get', 'caught'],
                    'heatmap-color': [
                        'interpolate',
                        ['linear'],
                        ['heatmap-density'],
                        0,
                        'rgba(33,102,172,0)',
                        0.2,
                        'rgb(103,169,207)',
                        0.4,
                        'rgb(209,229,240)',
                        0.6,
                        'rgb(253,219,199)',
                        0.8,
                        'rgb(239,138,98)',
                        1,
                        'rgb(178,24,43)',
                    ],
                    'heatmap-radius': 15
                }
            },
            'markers-point'
        );
    }

    /** Draw Markers as bubbles, more caught = bigger bubble **/
    drawMarkerBubbleMap(map) {
        map.addLayer(
            {
                'id': 'markers-point',
                'type': 'circle',
                'source': 'markers',
                'paint': {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['get', 'caught'],
                        0, 2,
                        20, 10
                    ],
                    'circle-color': [
                        'interpolate',
                        ['linear'],
                        ['get', 'caught'],
                        0, 'rgb(255,255,255)',
                        20, 'rgb(255,0,0)'
                    ],
                    'circle-stroke-color': 'black',
                    'circle-stroke-width': 1,
                }
            },
        );
    }

    renderMap() {
        return (
            <div className="report-page report-page-mapview">
                <div>
                    <h4>Traps</h4>
                </div>
                <div className="report-map-view-container">
                    <MapView
                        mapSettings={{
                            interactive: false
                        }}
                        extend={(map) => {
                            this.drawMap(map).then(() => {
                                this.drawMarkers(map);
                            });
                        }}
                    />
                </div>
            </div>
        );
    }

    renderHeatMap() {
        return (
            <div className="report-page report-page-heatmap">
                <div>
                    <h4>Heat Map</h4>
                </div>
                <div className="report-map-view-container">
                    <MapView
                        mapSettings={{
                            interactive: false
                        }}
                        extend={(map) => {
                            this.drawMap(map).then(() => {
                                this.drawMarkers(map);
                                this.drawHeatMap(map);
                            })
                        }}
                    />
                </div>
            </div>
        );
    }

    renderBubbleMap() {
        return (
            <div className="report-page report-page-bubblemap">
                <div>
                    <h4>Bubble Map</h4>
                </div>
                <div className="report-map-view-container">
                    <MapView
                        mapSettings={{
                            interactive: false
                        }}
                        extend={(map) => {
                            this.drawMap(map).then(() => {
                                this.drawMarkerBubbleMap(map);
                            })
                        }}
                    />
                </div>
            </div>
        );
    }

    renderReportComments() {
        return (
            <div className="report-page report-page-comments">
                <div>
                    <h4>Report Comments</h4>
                </div>
                <div className="report-comments-container">
                    <p>{this.props.comments.split('\n').map(str => <p>{str}</p>)}</p>
                </div>
            </div>
        );
    }

    render() {
        if (this.state.loading) return <Loading />;

        return <div className="report">
            <this.ReportPage header={false} >
                <div className="company-logo">
                    {this.state.companyLogoURL ?
                        <img
                            src={this.state.companyLogoURL}
                            style={{
                                width: '100%',
                                float: 'right'
                            }}
                        /> : <h3>{this.context.account.get('name')}</h3>
                    }
                </div>
                {this.renderDetails()}
            </this.ReportPage>

            <this.ReportPage>
                {this.renderDailyStats()}
            </this.ReportPage>

            <this.ReportPage>
                {this.renderMap()}
            </this.ReportPage>

            <this.ReportPage>
                {this.renderBubbleMap()}
            </this.ReportPage>

            <this.ReportPage>
                {this.renderHeatMap()}
            </this.ReportPage>

            <this.ReportPage>
                {this.renderReportComments()}
            </this.ReportPage>
        </div>;
    }
}

Report.contextType = AuthContext;