import React, {useEffect, useState} from "react";
import {useAuth} from "./AuthProvider";
import {useNavigate} from "react-router-dom";
import {Util} from "../common/Util";

function PageTopBar(props) {

    let auth = useAuth();
    let navigate = useNavigate();

    const isActive = function(...keys) {
        let activeParts = props.active || [];
        if (!Array.isArray(activeParts)) activeParts = [activeParts];
        for (const key of keys) {
            if (activeParts.indexOf(key) < 0) return "";
        }
        return " active";
    }

    const menuClass = function(...parts) {
        return "menu-item" + isActive(...parts);
    }


    return (<div className="page-topbar">
        <div className="logo-container">
            <img className="topbar-logo" src={'/assets/logo/TrapMasterLogo_words_transparent.png'}/>
        </div>
        <div className="topbar-menu">
            <a className={menuClass("overview")} onClick={() => {
                navigate('/map');
                return false;
            }}>Overview</a>

            <div className="menu-item-dropdown">
                <a className={menuClass("projects")} onClick={() => {
                    navigate('/projects');
                    return false;
                }}>Projects</a>
                <ProjectsDropdownMenu menuClass={menuClass} >
                    <a className={menuClass("projects", "create")} onClick={() => {
                            navigate('/projects/create');
                            return false;
                        }}>New Project</a>
                </ProjectsDropdownMenu>
            </div>

            <div className="menu-item-dropdown">
                <a className={menuClass("reports")} onClick={() => alert("Not ready yet.")}>Reports</a>
                <div className="menu-item-dropdown-content">
                    <a className={menuClass("reports", "create")} onClick={() => {
                        navigate('/report/create');
                        return false;
                    }}>New Report</a>
                </div>
            </div>

            <a className={menuClass("users")}onClick={() => alert("Not ready yet.")}>Users</a>
            <a className={menuClass("settings")} onClick={() => {
                navigate('/settings');
                return false;
            }}>Settings</a>

            <a className="menu-item" onClick={() => {
                // eslint-disable-next-line no-restricted-globals
                if (confirm("Are you sure you want to logout?")) {
                    auth.signout().then(() => {
                        alert("Logout Success");
                        navigate('/');
                    }, () => {
                        alert("Failed to logout.");
                    })
                }
            }}>Logout</a>
        </div>
        <div className="topbar-right">
            <p className="user-tag-container">Welcome, {auth.user.get('fullName')}</p>
        </div>
    </div>);
}


function ProjectsDropdownMenu(props) {
    let auth = useAuth();
    let navigate = useNavigate();

    let [loading, setLoading] = useState(true);
    let [projects, setProjects] = useState({});

    useEffect(() => {
        return Util.listenTo(auth.account.get('projects'), project => {
            console.log("Updating State for: ", project.id);
            if (project.get('active')) {
                projects[project.id] = project;
                setProjects({...projects});
            };
        }, () => {
            console.log("Loading Finished.");
            setLoading(false);
        });
    }, []);


    let project_links = [];
    if (loading) {
        project_links.push(<a key={"PROJECTS_LOADING"} className="menu-item" onClick={() => {
            return false;
        }}>Projects Loading...</a>);
    }
    else if (projects) {
        for (const [projectID, project] of Object.entries(projects)) {
            project_links.push(<a key={projectID} className={props.menuClass("projects", projectID)} onClick={() => {
                navigate('/projects/' + projectID);
                return false;
            }}>{project.get('name')}</a>);
        }

        if (Object.keys(projects).length) {
            project_links.push(<hr key="project_splitter"/>);
        }
    }


    return (
        <div className="menu-item-dropdown-content">
            {project_links}
            {props.children}
        </div>
    );
}



export default PageTopBar;