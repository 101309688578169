import { FireStore } from "../firebase/config";
import 'firebase/firestore';
import firebase from "firebase";

const Collection = Object.freeze({
    Markers: 'markers2',
    Projects: 'projects',
    Users: 'users',
    Accounts: 'accounts',
    Events: 'events',
});

class Query {

    /**
     * Query for project data.
     * @param project
     * @returns {FireStore.DocumentReference}
     */
    static fetchProject(project) {
        return prepareProjectReference(project);
    }

    /** Fetch user data **/
    static userData(user) {
        return prepareUserReference(user);
    }

    /** Fetch account data **/
    static accountData(account) {
        return prepareAccountReference(account);
    }

    /** Fetch Project Markers **/
    static projectMarkers(project) {
        return FireStore.collection(Collection.Markers).where('project', '==', prepareProjectReference(project));
    }

    /** Fetch events for marker **/
    static markerEvents(marker) {
        marker = prepareMarkerReference(marker);
        return marker.collection(Collection.Events);
    }

    /** Fetch Events for project **/
    static projectEvents(project) {
        return FireStore.collectionGroup(Collection.Events).where('project', '==', prepareProjectReference(project));
    }


}

/**
 * Ensure project reference.
 * @param project
 * @returns {FireStore.DocumentReference}
 */
const prepareProjectReference = project => {
    if (!(project instanceof firebase.firestore.DocumentReference)) {
        project = FireStore.collection(Collection.Projects).doc(project);
    }
    return project;
}

/**
 * Ensure Marker reference=.
 * @param marker
 * @returns {FireStore.DocumentReference}
 */
const prepareMarkerReference = marker => {
    if (!(marker instanceof firebase.firestore.DocumentReference)) {
        marker = FireStore.collection(Collection.Markers).doc(marker);
    }
    return marker;
}

const prepareUserReference = user => {
    if (!(user instanceof firebase.firestore.DocumentReference)) {
        user = FireStore.collection(Collection.Users).doc(user);
    }
    return user;
}

const prepareAccountReference = account => {
    if (!(account instanceof firebase.firestore.DocumentReference)) {
        account = FireStore.collection(Collection.Accounts).doc(account);
    }
    return account;
}

export {
    Collection,
    Query,
    prepareProjectReference,
    prepareMarkerReference,
};