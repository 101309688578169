

export class Util {
    static htmlDateToTimestamp(value) {
        return ((new Date(value)).getTime()) / 1000;
    }

    static listenTo(items, callback, onceAllLoaded) {
        
        const itemHasLoaded = [];
        const listeners = [];

        for (let i = 0; i < items.length; i++) {
            itemHasLoaded.push(new Promise((resolve) => {
                listeners.push(items[i].onSnapshot((payload) => {
                    callback(payload);
                    resolve();
                }));
            }))
        }

        if (onceAllLoaded) {
            Promise.all(itemHasLoaded).then(() => {
                onceAllLoaded();
            });
        }
    
        return function cleanup() {
            for (const removeListener of listeners) {
                removeListener();
            }
        };
    }

    static async loadImage(ref) {

    }
}
