import React from 'react';
import PageTopBar from "../component/PageTopBar";
import WebPage from "../component/WebPage";
import Loading from "../component/Loading";
import { FireStore } from '../firebase/config';
import {Query} from "../common/DB";
import { Util } from '../common/Util';
import {ListenerContainer} from "../common/ListenerContainer";
import {useNavigate} from "react-router-dom";

export function Page404() {
    let navigate = useNavigate();
    return (
        <div className="page page-settings">
            <h3>404</h3>
            <p>Opps, this is not the page you are looking for</p>
            <button
                className="btn btn-success"
                onClick={() => {
                    navigate("/");
                }}
            >Take me Home</button>
        </div>
    );
}