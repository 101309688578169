
export class ListenerContainer {

    constructor() {
        this.items = new Map();
    }

    add(key, listener) {
        this.items.set(key, listener);
        return listener;
    }

    remove(key) {
        if (this.items.has(key)) {
            const cleaner = this.items.get(key);
            cleaner();

            this.items.delete(key);
        }
    }

    shutdown() {
        const keys = this.items.keys();
        for (let i = 0; i < keys.length; i++) {
            this.remove(keys[i]);
        }
    }
}
