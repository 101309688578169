import React from "react";
import {useNavigate, useLocation, Navigate} from "react-router-dom";
import { app } from '../firebase/config';
import {Query} from "../common/DB";
import Loading from "./Loading";

/** Auth Context **/
let AuthContext = React.createContext(null);


/** Use Auth Context **/
function useAuth() {
    return React.useContext(AuthContext);
}



let listenerUser = null;
let listenerAccount = null;


class AuthProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: null,
            user: null,
            account: null,
            isReady: false
        };
    }

    componentDidMount() {
        // Prepare Auth State Listener
        app.auth().onAuthStateChanged(auth => {
            this.setState({isReady: false});
            console.log("Auth State Changed", auth);

            if (auth) {
                this.setState({auth: auth});
                listenerUser  = Query.userData(auth.uid).onSnapshot(userDocument => {
                    this.setState({user: userDocument});
                    if (listenerAccount) listenerAccount();
                    listenerAccount = userDocument.get('account').onSnapshot(accountDocument => {
                        this.setState({
                            account: accountDocument,
                            isReady: true
                        });
                    });
                });
            }
            else {
                this.reset().then(() => {
                    this.setState({isReady: true});
                });
            }
        });
    }

    reset() {
        return new Promise((resolve) => {
            this.setState({
                auth: null,
                user: null,
                account: null,
            }, resolve);
        });
    }

    signin(email, password) {
        return new Promise((resolve, reject) => {
            app.auth().signInWithEmailAndPassword(email, password)
                .then(() => {
                    console.log("Login Successful!");
                    resolve();
                })
                .catch(err => {
                    console.error("Login Failed", err.code, err.message);
                    reject(err.message);
                });
        });
    };

    signout() {
        return new Promise((resolve, reject) => {
            app.auth().signOut().then(resolve, reject);
        });
    };

    render() {
        return <AuthContext.Provider value={{
            isAuthReady: this.state.isReady,
            auth: this.state.auth,
            account: this.state.account,
            user: this.state.user,
            signin: this.signin,
            signout: this.signout
        }}>{this.props.children}</AuthContext.Provider>;
    }
}

/**
 * Helper to enforce authentication first.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function RequireAuth(props) {
    let auth = useAuth();
    let location = useLocation();
    console.log("Checking Auth", auth);
    if (!auth.auth) {
        return <Navigate to="/login" state={{from: location}} replace />;
    }

    return props.children;
}

function WaitForAuth(props) {
    let auth = useAuth();

    if (!auth.isAuthReady) {
        return (<div className='page'>            
            <Loading message="Loading session..."/>
        </div>);
    }

    return props.children;
}



export {
    RequireAuth,
    AuthProvider,
    useAuth,
    AuthContext,
    WaitForAuth
};
