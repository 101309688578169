import React from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken = 'pk.eyJ1Ijoic3Vic2VydmVyIiwiYSI6ImNrd2FtdWoxbDJtajkyb3A4NHhkbndhejMifQ.5CAuO-OLAT7VZ9aSCIr0ww';


export default class MapView extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
        this.map = React.createRef();
        this.mapElement = React.createRef();
    }

    componentDidMount() {
        this.map.current = new mapboxgl.Map({
            container: this.mapElement.current,
            style: 'mapbox://styles/subserver/ckwaq2ghl9qfb14qsd7jy8t6u',
            // style: 'mapbox://styles/subserver/ckwj9gop12ef015qagx23mvkz',
            ...this.props.mapSettings
        });

        if (this.props.locationControl) {
            // Add geolocate control to the map.
            this.map.current.addControl(
                new mapboxgl.GeolocateControl({
                    positionOptions: {
                        enableHighAccuracy: true
                    },
                    // When active the map will receive updates to the device's location as it changes.
                    trackUserLocation: true,
                    // Draw an arrow next to the location dot to indicate which direction the device is heading.
                    showUserHeading: true
                })
            );
        }

        if (this.props.extend) this.props.extend(this.map.current);

        if (this.props.onLoad) {
            this.map.current.on('load', () => {
                this.props.onLoad(this.map.current);
            });
        }
    }
    render() {
        return (
            <div className="map-container">
                <div ref={this.mapElement} className="map-view" />
                {this.props.children}
            </div>
        );
    }
}

export { mapboxgl };