import React from 'react';
import WebPage from "../component/WebPage";
import Loading from "../component/Loading";
import {ListenerContainer} from "../common/ListenerContainer";
import { FireStorage } from "../firebase/config";
import { Util } from "../common/Util";
import Modal from 'react-modal';

export default class SettingsPage extends WebPage {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingMessage: "",
            companyName: "",
            companyLogo: "",
            companyLogoURL: null,

            showCompanyLogoChangeModal: false,
        };

        this.listeners = new ListenerContainer();
    }

    componentDidMount() {
        this.setState({loading: true});

        const waiting = [];
        for (const loader of [
            this.loadCompanySettings.bind(this),
            this.loadCompanyLogo.bind(this),
        ]) {
            let result = loader();
            waiting.push(result);
        }

        Promise.all(waiting).then(() => {
            this.setState({loading: false});
        });
    }

    componentWillUnmount() {
        if (this.listeners) this.listeners.shutdown();
    }

    async loadCompanyLogo() {
        let logoPath = this.context.account.get('logo');
        if (logoPath) {
            console.log("Company Logo Path: ", logoPath);
            const storageRef = FireStorage.ref();
            const fileRef = storageRef.child(logoPath);

            try {
                const downloadURL = await fileRef.getDownloadURL();
                console.log("Company Logo URL: ", downloadURL);
                this.setState({ companyLogoURL: downloadURL });
            } catch (err) {
                console.log("Logo file does not exist!", logoPath);
            }
        }
    }

    async loadCompanySettings() {
        this.setState({ companyName: this.context.account.get('name') });
    }

    async saveCompanySettings() {
        const updates = {};

        if (this.state.companyName) updates.name = this.state.companyName;
        if (this.state.companyLogo) {
            const storageRef = FireStorage.ref();
            const storagePath = this.context.account.id + '/' + this.state.companyLogo.name;
            const logoRef = storageRef.child(storagePath);

            try {
                await logoRef.put(this.state.companyLogo);
            } catch (err) {
                return new Error("Failed to upload company logo.");
            }

            updates.logo = storagePath;
        }


        if (Object.keys(updates).length) {
            await this.context.account.ref.set(updates, { merge: true });
        }
    }

    renderCompanySettings() {
        return (
            <div className="settings company-settings">
                <div className="form-group mb-2">
                    <label htmlFor="settings-company-name">Company Name</label>
                    <input
                        className="form-control"
                        id="settings-company-name"
                        type="text"
                        placeholder="Company Name"
                        value={this.state.companyName}
                        onChange={(e) => {
                            this.setState({companyName: e.target.value});
                        }}
                    />
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="settings-company-logo">Company Logo</label>
                    <div className="row">
                        <div className="col-4">
                            <div className="settings-logo-container">
                                {this.state.companyLogoURL ? <img
                                    src={this.state.companyLogoURL}
                                    style={{
                                        width: '100%',
                                    }}
                                /> : <h4>None</h4>}
                            </div>
                        </div>
                        <div className="col">
                            <button className="btn btn-info" onClick={() => {
                                this.setState({showCompanyLogoChangeModal: true});
                            }}>{this.state.companyLogoURL ? "Change Logo" : "Upload Logo"}</button>
                        </div>
                    </div>

                    <Modal
                        isOpen={this.state.showCompanyLogoChangeModal}
                        onRequestClose={() => {
                            this.setState({showCompanyLogoChangeModal: false})
                        }}
                        contentLabel="Set Logo"
                        ariaHideApp={false}
                        >
                        <input
                            className="form-control"
                            id="settings-company-logo"
                            type="file"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                this.setState({
                                    companyLogo: file,
                                    showCompanyLogoChangeModal: false,
                                    companyLogoURL: URL.createObjectURL(file)
                                });
                            }}
                        />

                        <button className="btn btn-danger" onClick={() => {
                            this.setState({showCompanyLogoChangeModal: false});
                        }}>Cancel</button>
                    </Modal>
                </div>

                <div className="form-group mb-2">
                    <button className="btn btn-success" onClick={async e => {
                        this.setState({loading: true, loadingMessage: "Saving Settings..."});
                        await this.saveCompanySettings();
                        this.setState({loading: false, loadingMessage: ""});
                    }}>Save</button>
                </div>
            </div>
        );
    }

    render() {
        let content = <Loading message={this.state.loadingMessage || "Loading Settings..."} />;

        if (!this.state.loading) {
            content = (<div>
                {this.renderCompanySettings()}
            </div>);
        }

        return (
            <div className="page page-settings">
                {content}
            </div>
        );
    }

}